<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Assignments</h3>
        </b-col>
        <b-col md="4" class="px-3 py-1 text-right">
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            class="align-items-center"
            @click="
              () => {
                this.$router.push({ name: 'InstructorAssignmentCreate' });
              }
            "
          >
            <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
            <span class="text-colorWhite">New Assignment</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fields"
          :items="assignments"
          thead-class="tableHeadColor"
          small
          rounded
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(name)="row">
            <div class="py-3">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.name ? row.item.name : "" }}
              </span>
            </div>
          </template>
          <template #cell(deadline)="row">
            <span
              class="font-weight-bold text-colorBlack m-0"
              style="font-size: 1rem"
            >
              {{ row.item.deadline ? row.item.deadline : "" }}
            </span>
          </template>


          <template #cell(classes)="row">
            <span
              class="font-weight-bold text-colorBlack m-0"
              style="font-size: 1rem"
            >
              {{ row.item.classes_data.name ? row.item.classes_data.name : "" }}
            </span>
          </template>

          <template #cell(subject)="row">
            <span
              class="font-weight-bold text-colorBlack m-0"
              style="font-size: 1rem"
            >
              {{ row.item.subject_data.name ? row.item.subject_data.name : "" }}
            </span>
          </template>

          <template #cell(SubmissionStatus)="row">
            <div class="py-1">
              <span></span>
              <b-progress
                class="mt-1"
                :value="row.item.SubmissionStatus"
                :max="max"
                show-progress
                animated
              ></b-progress>
              <span
                class="font-weight-bold text-colorBlack"
                style="font-size: 0.8rem"
                >{{
                  row.item.SubmissionStatus ? row.item.SubmissionStatus : ""
                }}
                / 20</span
              >
            </div>
          </template>

          
          <template #cell(GradingStatus)="row">
            <div>
              <span></span>
              <b-progress
                class="mt-1"
                :value="row.item.GradingStatus"
                :max="max"
                show-progress
                animated
              ></b-progress>
              <span
                class="font-weight-bold text-colorBlack"
                style="font-size: 1rem"
                >{{ row.item.GradingStatus ? row.item.GradingStatus : "" }} /
                20</span
              >
            </div>
          </template>
          <template #cell(manage)="row">
            <b-link>
              <img
                src="@/assets/images/icons/instructor/assignment/Delete.svg"
                img-fluid
                class="mx-1"
                width="30px"
                height="30px"
                @click="removeAssignment(row.item)"
              />
            </b-link>
            <b-link :to="{ name: 'InstructorAssignmentCreate' }">
              <img
                src="@/assets/images/icons/instructor/assignment/Edit.svg"
                img-fluid
                width="30px"
                height="28px"
                class="cursor-pointer"
                @click="editAssignment(row.item)"
            /></b-link>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      fields: [
        { key: "name", label: "Assignmnet" },
        { key: "deadline", label: "Deadline" },
        { key: "SubmissionStatus", label: "Submission Status" },
        { key: "subject", label: "Subject " },
        { key: "classes", label: "Class " },
        { key: "GradingStatus", label: "Grading Status" },
        { key: "manage", label: "" },
      ],
      max: 20,
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      assignments: [],
    };
  },
  methods: {
    ...mapActions({
      getAssignments: "appData/getAssignments",
      deleteAssignment: "appData/deleteAssignment",
      setAssignmentData: "appData/setAssignmentData",
    }),

    async fetchPaginatedData() {
      try {
        const res = await this.getAssignments({
          pageNumber: this.currentPage,
          name: this.name,
        });
        this.assignments = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    editAssignment(assignment){
      this.setAssignmentData(assignment);
      this.$router.push({ name: 'InstructorAssignmentCreate' });
    },

    async removeClass(assignment) {
      this.assignment = assignment;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteAssignment({
              pk: this.assignment.id,
            });
            if (res.status === 204) {
              this.$swal({
                title: "Assignment deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
};
</script>

<style></style>
